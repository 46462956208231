.rules-table {
    width: 100%;
  }
  
  .table-row {
    text-align: center;
  }
  
  tr:nth-child(odd) {
    background-color: #abd9c3;
  }
  
  .cross {
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: ' ';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }