.input {
    border-radius: 0.5em;
    font-size: 1em;
    padding: 0.75em;
    width: 90%;
}

.submit-button {
    padding: 0.75em;
}

.url-wrapper {
    width: 75%;
    display: inline-block;
    .input-wrapper {
        width: 50%;
        display: inline-block;
        text-align: center;
    }
}

.status-submit-wrapper {
    width: 25%;
    display: inline-block;
    .input-wrapper {
        display: inline-block;
        text-align: center;
    }
}

#statusSelectList {
    height: 3.5em;
    border-radius: 0.5em;
}

.status-wrapper {
    width: 70%;
    .header {
        display: block;
    }
}

.submit-wrapper {
    width: 25%;
}

.errors {
    margin-left: 1.5em;
    color: red;
}