body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.banner {
  background: #214232;
  padding-left: 1em;
  margin-bottom: 0.75em;
}

.banner>span>a {
  color: white;
  text-decoration: none;
};

.header {
  display: block;
  margin-bottom: 0.25em;
}

.home-link {
  font-size: 3em;
  line-height: 2em;
}

.nav-link {
  margin-left: 3em;
  font-size: 1em;
  font-weight: bold;
}